<template>
  <v-row no-gutters class="header-row">
    <v-col class="left-container"  md="2" xs="6">
      <v-btn class="first-button" rounded="pill">
        <label class="button-label">ESPACE FOURNISSEURS</label>
      </v-btn>
      <v-btn class="second-button" rounded="pill">
        <label class="button-label">ESPACE ACHETEURS</label>
      </v-btn>
    </v-col>
    <v-col class="right-container"  md="2"  xs="6">
      <v-btn class="login-button" rounded="pill" @click="this.$router.push('/login')" v-if="!userName">
        <label class="button-label">Se connecter</label>
      </v-btn>
      <v-menu anchor="bottom end" rounded content-class="test"  v-else>
        <template v-slot:activator="{ props }">
          <v-img v-bind="props" style="background-color: azure;border-radius: 30px;"
            :src="require('@/assets/icons/user.svg')" width="20"></v-img>
        </template>
        <v-card>
          <v-card-text>
            <div class="mx-auto text-center">
              <h3>{{ userName }}</h3>
              <v-divider class="my-3"></v-divider>
              <v-btn rounded variant="text" @click="this.$router.push('/search/save/list')">
                Recherche Sauvgarder
              </v-btn>
              <v-divider class="my-3"></v-divider>
              <v-btn @click="logout()">
                Me déconnecter
              </v-btn>
            </div>
          </v-card-text>
        </v-card>
      </v-menu>
    </v-col>

  </v-row>
</template>



<script>
import { LOGIN_ROUTE } from '@/router/routes';
import { GET_USER, IS_USER_AUTHENTICATED } from '@/store/storeconstants';

export default {
  name: 'WelcomeVIew',
  data() {
    return {
      showMenu: false,
      userName: localStorage.getItem('userName') || null
    };
  },
  methods: {
    logout() {
      localStorage.removeItem('token');
      localStorage.removeItem('refreshToken');
      localStorage.removeItem('user');
      localStorage.removeItem('userName');
      this.userName = null;
      this.$router.push(LOGIN_ROUTE);
    },
  },
  computed: {
    user() {
      return this.$store.getters[`auth/${GET_USER}`];
    },
    isAuthenticated() {
      return this.$store.getters[`auth/${IS_USER_AUTHENTICATED}`];
    },
    isUserLoggedIn() {
      return !!this.userName;
    }
  },
}
</script>

<style scoped>
.header-row {
  width: 100%;
  min-height: 4.12119rem;
  flex-shrink: 0;
  background: #304353;
  margin-top: 0;
  vertical-align: middle;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
}

.left-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.right-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.first-button, .second-button {
  width: 8.93444rem;
  height: 1.63863rem;
  flex-shrink: 0;
  border-radius: 1.22513rem;
}

.first-button {
  background-color: #DCC6A5 !important;
}

.second-button {
  background: #F3F0E9;
  margin-left: 0.47rem;
}

.login-button {
  width: 8rem;
  height: 1.63863rem;
  flex-shrink: 0;
  border-radius: 1.22513rem;
  background: #DCC6A5 !important;
}

.button-label {
  color: #304353;
  font-family: Rubik-Regular;
  font-size: 0.46875rem;
  font-style: normal;
  font-weight: 700;
  line-height: 0.58594rem;
}
.v-responsive{
  max-width: 15% !important;
}

@media (max-width: 600px) {
  .header-row {
    flex-direction: column;
    align-items: flex-start;
  }

  .left-container, .right-container {
    justify-content: center;
    margin-bottom: 1rem;
  }

  .first-button, .second-button, .login-button {
    width: 50%;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;

  }

}
</style>