import axios from "axios";


let url = process.env.VUE_APP_API;

class AvisService {


    details(id) {
        const accessToken = localStorage.getItem('token');
        const config = {
            headers: {
                'Content-Type': 'application/json', 'Authorization': `Bearer ${accessToken}`
            }
        };
        return axios.get(url + '/api/boamp/advert/'+id,config
        ).then(
            (response) => {
                return response.data;
            }
        )
    }


}

export default new AvisService();