import {IS_USER_AUTHENTICATED, GET_USER, GET_ERRORS} from "../storeconstants";

export default {
    [IS_USER_AUTHENTICATED](state) {
        return state.authenticated;
    },

    [GET_USER](state) {
        return state.user;
    },
    [GET_ERRORS](state) {
        return state.errors;
    }
}