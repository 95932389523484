<template>
  <div class="sub-header-menu py-6">
    <v-col>
      <v-row>
        <div class="hamburger" @click="toggleMenu">
          <img alt="menu" class="menu-icon" src="@/assets/icons/menu.svg" />
        </div>
        <img class="logo" alt="logo" :src="require('@/assets/images/logo.svg')" />

        <!-- Overlay for mobile menu -->
        <div class="mobile-overlay" v-show="isMenuOpen" @click="toggleMenu"></div>

        <!-- Menu items -->
        <div class="sub-menu-items" :class="{ 'show-menu': isMenuOpen }">
          <v-row>
            <v-col class="d-flex mx-1 px-0 menuDirection">
              <div>
                <img alt="home" class="home" :src="require('@/assets/icons/home.svg')" />
              </div>
              <div>
                Marchés Publiques
              </div>
              <div>
                Espace Fournisseurs
              </div>
              <div>
                Recherche avancée
              </div>
              <div>
                Dématérialisation
              </div>
              <div>
                Liens Utiles
              </div>
              <div>
                Documentation
              </div>
            </v-col>
          </v-row>
        </div>
      </v-row>
    </v-col>
  </div>
</template>

<script>
export default {
  name: 'SubHeaderView',
  data() {
    return {
      isMenuOpen: false
    };
  },
  methods: {
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    }
  }
}
</script>

<style>
.sub-header-menu {
  padding-left: 14.71rem !important;
  padding-right: 14.71rem !important;
  margin-top: 0 !important;
  width: 100%;
  z-index: 15;
  position: relative;
}

.sub-menu-items {
  color: #304353;
  font-family: Rubik-Regular;
  font-size: 0.8rem;
  font-style: normal;
  font-weight: 400;
  line-height: 0.41019rem;
  /* 116.667% */
  display: flex;
  align-items: center;
}

.sub-menu-items div {
  margin-left: 1.52rem;
}

.logo {
  width: 10rem;
}

.home {
  width: 0.66281rem;
  height: 0.62425rem;
  flex-shrink: 0;
}

.hamburger {
  display: none;
  cursor: pointer;
}

.menu-icon {
  width: 1.5rem;
  height: auto;
}

.mobile-overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

@media (max-width: 900px) {
  .sub-header-menu {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }

  .menuDirection {
    flex-direction: column;
  }

  .sub-menu-items {
    display: none;
    position: fixed;
    top: 0.5rem;
    left: 0;
    right: 0;
    background-color: white;
    padding: 1rem;
    z-index: 1000;
    flex-direction: column;
    align-items: flex-start;
  }

  .sub-menu-items.show-menu {
    display: flex;
  }

  .sub-menu-items div {
    margin: 0.5rem 0;
    text-align: left;
  }

  .hamburger {
    display: block;
    margin-right: auto;
  }

  .logo {
    width: 10rem;
    margin-right: auto;
  }

  .mobile-overlay {
    display: block;
  }
}
</style>
