import axios from 'axios';
import router from "../router";
import { LOGIN_ROUTE } from '@/router/routes';


export default function execute() {
    axios.interceptors.response.use(function (response) {
        return response
    }, async function (error) {
        if (error.response.status === 401) {
            await router.push(LOGIN_ROUTE);
        }
        return Promise.reject(error)
    })
}