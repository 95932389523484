<template>
  <v-app>
    <v-container fluid class="py-0 px-0">
      <v-col>
        <v-row>
          <HeaderView/>
        </v-row>
        <v-row>
          <SubHeaderView/>
        </v-row>
        <v-row>
          <RouterView/>
        </v-row>
        <v-row>
          <SubFooterView/>
        </v-row>
        <v-row>
          <FooterView/>
        </v-row>
      </v-col>

    </v-container>
  </v-app>

</template>

<script>
import FooterView from "@/components/base/FooterView.vue";
import HeaderView from "@/components/base/HeaderView.vue";
import SubHeaderView from "@/components/base/SubHeader.vue";
import SubFooterView from "@/components/base/SubFooterView.vue";

export default {
  name: 'App',
  components: {SubFooterView, FooterView, SubHeaderView, HeaderView},
}
</script>

<style>
@font-face {
  font-family: "Rubik-Regular";
  src: local("Rubik-Regular"),
  url("~@/assets/fonts/static/Rubik-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Rubik-Bold";
  src: local("Rubik-Bold"),
  url("~@/assets/fonts/static/Rubik-Bold.ttf") format("truetype");
}

#app {
  font-family: Rubik-Regular, sans-serif;
  -webkit-font-smoothing: antialiased;
  color: #2c3e50;
}

label {
  font-weight: bold
}
</style>