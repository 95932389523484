import axios from "axios";
import {queryCreator} from "@/helpers/queryCreator";
import {extractValues} from "@/helpers/extractValues";


let url = process.env.VUE_APP_API;

class SearchService {


    search(search) {
        let queryResult = extractValues(search);
        return axios.get(url + '/api/boamp/search' + '?' + queryResult + '&pageSize=20').then((response) => {
            return response.data;
        })
    }

    cpvSearch(label) {
        return axios.get(url + '/api/cpv/search' + '?label=' + label).then((response) => {
            return response.data;
        })
    }

    saveSearch(search) {
        const accessToken = localStorage.getItem('token');
        const config = {
            headers: {
                'Content-Type': 'application/json', 'Authorization': `Bearer ${accessToken}`
            }
        };
        return axios.post(url + '/api/enterprise/boamp/savedsearches/add', search, config).then((response) => {
            return response.data;
        })
    }

    getSavedSearch(param) {
        const accessToken = localStorage.getItem('token');
        const id = localStorage.getItem('user');
        let queryResult = queryCreator(param);
        const config = {
            headers: {
                'Content-Type': 'application/json', 'Authorization': `Bearer ${accessToken}`
            }
        };
        return axios.get(url + `/api/enterprise/${id}/boamp/savedsearches?` + queryResult + '&pageSize=20', config).then((response) => {
            return response.data;
        })
    }
    deleteSavedSearch(id) {
        const accessToken = localStorage.getItem('token');
        const config = {
            headers: {
                'Content-Type': 'application/json', 'Authorization': `Bearer ${accessToken}`
            }
        };
        return axios.delete(url + `/api/enterprise/boamp/savedsearches/remove/${id}?`, config).then((response) => {
            return response.data;
        })
    }
    updateSavedSearch(param) {
        const accessToken = localStorage.getItem('token');
        const config = {
            headers: {
                'Content-Type': 'application/json', 'Authorization': `Bearer ${accessToken}`
            }
        };
        return axios.put(url + `/api/enterprise/boamp/savedsearches/update`,param, config).then((response) => {
            return response.data;
        })
    }

}

export default new SearchService();