import AuthService from "@/service/auth/auth.service";
import {SET_AUTHENTICATION, SET_AUTHENTICATION_TOKEN, SET_ERRORS} from "@/store/storeconstants";

export default {
    [SET_AUTHENTICATION_TOKEN]({commit}, user) {
        return AuthService.login(user).then(
            (token) => {
                commit(`${SET_AUTHENTICATION_TOKEN}`, token);
                commit(`${SET_AUTHENTICATION}`, true);
                return Promise.resolve(token);
            },
            (error) => {
                commit(`${SET_ERRORS}`, error.response.data.errors);
                commit(`${SET_AUTHENTICATION}`, false);
                return Promise.reject(error);
            }
        )
    }
}