import {createStore} from 'vuex'
import auth from './auth/index'
import register from './register/index'
import search from './search/index'
import avis from './avis/index'
import favorites from './favorite/index'
import password from './password/index'

export default createStore({
    state: {},
    getters: {},
    mutations: {},
    actions: {},
    modules: {
        auth,
        register,
        search,
        avis,
        favorites,
        password
    }
})