export const SET_AUTHENTICATION = '[mutations] set authentication';
export const SET_AUTHENTICATION_TOKEN = '[mutations] set authentication token';
export const IS_USER_AUTHENTICATED = '[getters] is user authenticated';
export const SET_USER = '[mutations] set user';
export const GET_USER = '[getters] get user';
export const GET_ERRORS = '[getters] get error';
export const SET_ERRORS = '[mutations] set errors';
export const REGISTER = '[mutations] set register';
export const SEARCH = '[mutations] set search';
export const CPV_SEARCH = '[mutations] set cpv search';
export const GET_SEARCH = '[getters] get search';
export const GET_CPV_SEARCH = '[getters] get cpv search';
export const GET_DETAILS = '[getters] get avis details';
export const SAVE_SEARCH = '[mutations] set save Search';
export const GET_SAVE_SEARCH = '[getters] get saved Search';
export const SET_SAVE_SEARCH = '[mutations] set saved Search';
export const SET_DELETE_SAVE_SEARCH = '[mutations] set delete saved Search';
export const SET_UPDATE_SAVE_SEARCH = '[mutations] set update saved Search';
export const SET_FAVORITES = '[mutations] set favorites';
export const GET_FAVORITES = '[getters] get favorites';
export const ADD_FAVORITES = '[mutations] set save favorite';
export const DELETE_FAVORITES = '[mutations] set delete favorite';
export const SET_RESET = '[mutations] set reset password';
export const SET_CHANGE = '[mutations] set change password';