import {REGISTER, SET_ERRORS} from "@/store/storeconstants";
import RegisterService from "@/service/register/register.service";

export default {
    [REGISTER]({commit}, user) {
        return RegisterService.register(user).then(
            (token) => {
                commit(`${REGISTER}`);
                return Promise.resolve(token);
            },
            (error) => {
                commit(`${SET_ERRORS}`, error.response.data.errors);
                return Promise.reject(error);
            }
        )
    }
}