import {GET_DETAILS, SET_ERRORS} from "../storeconstants";

export default {

    [SET_ERRORS](state, error) {
        state.error = error;
    },
    [GET_DETAILS](state, details) {
        state.details = details;
    },
}