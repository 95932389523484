import {
    CPV_SEARCH,
    SAVE_SEARCH,
    SEARCH,
    SET_DELETE_SAVE_SEARCH,
    SET_ERRORS,
    SET_SAVE_SEARCH, SET_UPDATE_SAVE_SEARCH
} from "@/store/storeconstants";
import SearchService from "@/service/search/search.service";

export default {
    [SEARCH]({commit}, search) {
        return SearchService.search(search).then(
            (result) => {
                commit(`${SEARCH}`, result);
                return Promise.resolve(result);
            },
            (error) => {
                commit(`${SET_ERRORS}`, error.response.data.errors);
                return Promise.reject(error);
            }
        )
    },
    [CPV_SEARCH]({commit}, label) {
        return SearchService.cpvSearch(label).then(
            (result) => {
                commit(`${CPV_SEARCH}`, result);
                return Promise.resolve(result);
            },
            (error) => {
                commit(`${SET_ERRORS}`, error.response.data.errors);
                return Promise.reject(error);
            }
        )
    },
    [SAVE_SEARCH]({commit}, search) {
        return SearchService.saveSearch(search).then(
            (result) => {
                return Promise.resolve(result);
            },
            (error) => {
                commit(`${SET_ERRORS}`, error.response.data.errors);
                return Promise.reject(error);
            }
        )
    },
    [SET_SAVE_SEARCH]({commit}, search) {
        return SearchService.getSavedSearch(search).then(
            (result) => {
                commit(`${SET_SAVE_SEARCH}`, result);
                return Promise.resolve(result);
            },
            (error) => {
                commit(`${SET_ERRORS}`, error.response.data.errors);
                return Promise.reject(error);
            }
        )
    },
    [SET_DELETE_SAVE_SEARCH]({commit}, id) {
        return SearchService.deleteSavedSearch(id).then(
            (result) => {
                return Promise.resolve(result);
            },
            (error) => {
                commit(`${SET_ERRORS}`, error.response.data.errors);
                return Promise.reject(error);
            }
        )
    },
    [SET_UPDATE_SAVE_SEARCH]({commit}, search) {
        return SearchService.updateSavedSearch(search).then(
            (result) => {
                return Promise.resolve(result);
            },
            (error) => {
                commit(`${SET_ERRORS}`, error.response.data.errors);
                return Promise.reject(error);
            }
        )
    }
}