export function queryCreator(object) {
    const filteredParams = Object.entries(object)
        .filter(([, value]) => {
            if (Array.isArray(value)) {
                return value.length > 0; // Exclude keys with empty arrays
            }
            return value !== undefined && value !== null && value !== '';
        });

    const queryParams = filteredParams.map(([key, value]) => {
        const encodedKey = encodeURIComponent(key);
        const encodedValue = Array.isArray(value) ? JSON.stringify(value) : encodeURIComponent(value);
        return `${encodedKey}=${encodedValue}`;
    });

    return queryParams.join('&');
}




