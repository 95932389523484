import {GET_CPV_SEARCH, GET_ERRORS, GET_SAVE_SEARCH, GET_SEARCH} from "../storeconstants";

export default {
    [GET_ERRORS](state) {
        return state.errors;
    },
    [GET_SEARCH](state) {
        return state.search;
    },
    [GET_CPV_SEARCH](state) {
        return state.cpvSearch;
    },
    [GET_SAVE_SEARCH](state) {
        return state.savedSearches;
    }
}