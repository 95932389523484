import axios from "axios";
import {queryCreator} from "@/helpers/queryCreator";


let url = process.env.VUE_APP_API;

class FavoriteService {


    add(id) {
        const accessToken = localStorage.getItem('token');
        const config = {
            headers: {
                'Content-Type': 'application/json', 'Authorization': `Bearer ${accessToken}`
            }
        };
        const param = {
            enterprise: localStorage.getItem('user'),
            idWeb: id
        }
        return axios.post(url + '/api/enterprise/boamp/favorites/add', param, config
        ).then(
            (response) => {
                return response.data;
            }
        )
    }

    delete(id) {
        const accessToken = localStorage.getItem('token');
        const param = {
            enterprise: localStorage.getItem('user'),
            idWeb: id
        }
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            },
            data: param
        };

        return axios.delete(url + '/api/enterprise/boamp/favorites/remove', config
        ).then(
            (response) => {
                return response.data;
            }
        )
    }

    getFavoriteList(param) {
        const accessToken = localStorage.getItem('token');
        const id = localStorage.getItem('user');
        let queryResult = queryCreator(param);
        const config = {
            headers: {
                'Content-Type': 'application/json', 'Authorization': `Bearer ${accessToken}`
            }
        };
        return axios.get(url + `/api/enterprise/${id}/boamp/favorites?` + queryResult + '&pageSize=20', config).then((response) => {
            return response.data;
        })
    }

}

export default new FavoriteService();