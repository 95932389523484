export function extractValues(parameters) {
    try {
        const keyValuePairs = Object.entries(parameters).map(([key, value]) => {
            if (Array.isArray(value) && value.every(item => typeof item === 'object' && item !== null && item.code !== undefined)) {
                // If the value is an array of objects with a "code" property, extract "code" values and join with space
                const codeValues = value.map(item => item.code).join(' ');
                return `${encodeURIComponent(key)}=${codeValues}`;
            } else if (Array.isArray(value)) {
                // If the value is an array, encode each item and join them with commas
                return `${encodeURIComponent(key)}=${value.map(item => encodeURIComponent(item)).join(',')}`;
            } else {
                // For simple strings or other types, encode the key-value pair
                return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
            }
        });

        return keyValuePairs.join('&');
    } catch (error) {
        console.error('Error building query string:', error);
        return; // or handle the error accordingly
    }
}