import passwordService from "@/service/password/password.service";
import {SET_RESET, SET_ERRORS, SET_CHANGE} from "@/store/storeconstants";

export default {
    [SET_RESET]({commit}, user) {
        return passwordService.reset(user).then(
            (token) => {
                return Promise.resolve(token);
            },
            (error) => {
                commit(`${SET_ERRORS}`, error.response.data.errors);
                return Promise.reject(error);
            }
        )
    },
    [SET_CHANGE]({commit}, user) {
        return passwordService.change(user).then(
            (token) => {
                return Promise.resolve(token);
            },
            (error) => {
                commit(`${SET_ERRORS}`, error.response.data.errors);
                return Promise.reject(error);
            }
        )
    }
}