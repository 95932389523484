import {SET_ERRORS, SET_FAVORITES} from "../storeconstants";

export default {

    [SET_ERRORS](state, error) {
        state.error = error;
    },
    [SET_FAVORITES](state, favorites) {
        state.favorites = favorites;
    },
}