import {ADD_FAVORITES, SET_ERRORS, SET_FAVORITES,DELETE_FAVORITES} from "@/store/storeconstants";
import favoriteService from "@/service/favorite/favorite.service";


export default {

    [SET_FAVORITES]({commit}, params) {
        return favoriteService.getFavoriteList(params).then(
            (result) => {
                commit(`${SET_FAVORITES}`, result);
                return Promise.resolve(result);
            },
            (error) => {
                commit(`${SET_ERRORS}`, error.response.data.errors);
                return Promise.reject(error);
            }
        )
    },
    [ADD_FAVORITES]({commit}, id) {
        return favoriteService.add(id).then(
            (result) => {
                return Promise.resolve(result);
            },
            (error) => {
                commit(`${SET_ERRORS}`, error.response.data.errors);
                return Promise.reject(error);
            }
        )
    },
    [DELETE_FAVORITES]({commit}, id) {
    return favoriteService.delete(id).then(
        (result) => {
            return Promise.resolve(result);
        },
        (error) => {
            commit(`${SET_ERRORS}`, error.response.data.errors);
            return Promise.reject(error);
        }
    )
},

}