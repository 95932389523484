import {SET_AUTHENTICATION, SET_USER, SET_ERRORS, SET_AUTHENTICATION_TOKEN} from "../storeconstants";

export default {

    [SET_AUTHENTICATION](state, authenticated) {
        state.authenticated = authenticated

    },
    [SET_USER](state, user) {
        state.user = user
    },
    [SET_AUTHENTICATION_TOKEN](state, token) {
        state.token = token;
        state.errors = [];
    },
    [SET_ERRORS](state, error) {
        state.token = null;
        state.errors = error;
    },
}