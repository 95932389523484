import axios from "axios";

let url = process.env.VUE_APP_API;

class RegisterService {


    register(user) {
        return axios.post(url + '/api/enterprise/register', {
                email: user.email,
                password: user.password,
                confirmPassword: user.confirmPassword,
                firstName: user.firstName,
                lastName: user.lastName,
                phone: user.phone,
                enterpriseName: user.enterpriseName,
                departmentCode: user.departmentCode,
                activityDescription: user.activityDescription,
            }
        ).then(
            (response) => {
                return response.data;
            }
        )
    }

}

export default new RegisterService();