import {GET_DETAILS, SET_ERRORS} from "@/store/storeconstants";
import AvisService from "@/service/avis/avis.service";

export default {
    [GET_DETAILS]({commit}, id) {
        return AvisService.details(id).then(
            (result) => {
                commit(`${GET_DETAILS}`, result);
                return Promise.resolve(result);
            },
            (error) => {
                commit(`${SET_ERRORS}`, error.response.data.errors);
                return Promise.reject(error);
            }
        )
    },
}