import mutations from './mutations';
import getters from './getters';
import actions from './actions';

export default {
    namespaced: true,
    state() {
        return {
            errors: [],
            search: [],
            cpvSearch: [],
            savedSearches:[]
        }
    },
    mutations,
    getters,
    actions
}