import {CPV_SEARCH, SEARCH, SET_ERRORS, SET_SAVE_SEARCH} from "../storeconstants";

export default {

    [SET_ERRORS](state, error) {
        state.error = error;
    },
    [SEARCH](state, search) {
        state.search = search;
    },
    [CPV_SEARCH](state, cpvSearch) {
        state.cpvSearch = cpvSearch;
    },
    [SET_SAVE_SEARCH](state, savedSearches) {
        state.savedSearches = savedSearches;
    },
}